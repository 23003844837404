import {} from 'react';
import { makeStyles } from 'tss-react/mui';

const flexBox = {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    flex: 1
} as const;

export default makeStyles()((theme) => ({
    root: {
        ...flexBox,
        gap: 32
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main
    },
    error: {
        margin: 0,
        fontSize: 14,
        color: 'red',
        textAlign: 'center',
        width: '100%'
    },
    loader: {
        width: '80%',
        margin: 'auto',
        borderRadius: 2
    },
    textsContainer: {
        ...flexBox,
        color: '#282D31',
        gap: 8,
        '& *': {
            margin: 0
        }
    },
    fieldsContainer: {
        ...flexBox,
        gap: 24
    },
    actionsContainer: {
        ...flexBox,
        gap: 16
    },
    submitButton: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '16px',
        background: '#282D31',
        color: '#FFFFFF',
        boxShadow: 'none !important',
        padding: '20px 24px',
        borderRadius: 18,
        '&:hover': {
            background: '#4D4D4D'
        },
        '&:focus': {
            background: '#4D4D4D'
        },
        '&:active': {
            background: '#000000'
        }
    },
    recoverButton: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '16px',
        background: '#FFF',
        color: '#282D31',
        boxShadow: 'none !important',
        padding: '19px 24px',
        border: '1px solid #EEE !important',
        borderRadius: 18,
        '&:hover': {
            background: '#FAFAFA'
        },
        '&:focus': {
            background: '#FAFAFA'
        },
        '&:active': {
            background: '#F5F5F5'
        }
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 16
    },
    oauthDivider: {
        marginTop: 16,
        marginBottom: 16,
        color: '#8385A3',
        width: '100%',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        '&:before': {
            content: '""',
            flex: 1,
            height: 1,
            background: '#ECECEC'
        },
        '&:after': {
            content: '""',
            flex: 1,
            height: 1,
            background: '#ECECEC'
        }
    }
}));
