export enum SearchType {
    simple = 'simple',
    elastic = 'elastic',
    product = 'product'
}

export enum ChangeTrigger {
    input = 'input',
    select = 'select'
}

export type SearchFieldValue = {
    type: SearchType;
    query: string;
    software: string;
    version: string;
};
