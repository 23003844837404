import React, { useCallback, useReducer, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button, Checkbox, FormControlLabel, LinearProgress, Typography } from '@mui/material';

import API from 'app/API';
import TextField from 'app/ui-kit/components/TextField';
import Hubspot from 'main/common/Hubspot';
import OAuthButtonGroup from 'pages/components/OAuthButtonGroup';
import { useHubSpotOauthRedirect } from 'pages/hooks/use-hubspot-redirection';
import useLoginCaptcha from 'pages/hooks/use-login-captcha';
import gtagEvent from 'utils/gtag-event';
import isOkResponse from 'utils/is-ok-response';

import useStyles from './styles';

import PasswordField from '../PasswordField';

interface Props {
    trial?: boolean;
    className?: string;
    showHeader?: boolean;
    redirectUrl?: string;
    enterprise?: boolean | ((backTo?: string, parameters?: object) => void);
}

const SignUp: React.FC<Props> = ({ trial, className, showHeader = true, redirectUrl, enterprise = false }) => {
    const { classes, cx } = useStyles();

    const history = useHistory();

    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [eula, toggleEula] = useReducer((state) => !state, true);
    const [marketingAgreement, toggleMarketingAgreement] = useReducer((state) => !state, true);

    const [errorText, setErrorText] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const { captcha, getCaptcha, resetCaptcha } = useLoginCaptcha();

    const buildLink = useCallback(
        (link: string) => (trial ? `/trial/${link}`.replace(/\/\//g, '/').replace(/\/$/, '') : link),
        [trial]
    );

    const oAuthProps = useHubSpotOauthRedirect({
        baseUrl: redirectUrl || (trial ? '/trial' : '/search'),
        marketingAgreement
    });

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await API.fetchWithToken(API.ADD, {
                email,
                username,
                password,
                marketingAgreement: Number(marketingAgreement),
                captcha: getCaptcha(),
                trial
            });
            if (isOkResponse(response)) {
                gtagEvent('signup-success');
                Hubspot.send(Hubspot.SIGNUP, { email, username });
                if (marketingAgreement) {
                    Hubspot.send(Hubspot.MARKETING_AGREEMENT, { email });
                }
                setErrorText('');
                setIsSuccess(true);
                if (redirectUrl) {
                    history.push(redirectUrl);
                }
            } else {
                gtagEvent('signup-error');
                setErrorText(response.data.error || 'Something went wrong');
                resetCaptcha();
            }
        } catch (error) {
            setErrorText(error.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    }, [email, getCaptcha, history, marketingAgreement, password, redirectUrl, resetCaptcha, trial, username]);

    const handleSignUpClick = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await API.fetch(API.CHECK_EMAIL, { email });
            if (isOkResponse(response)) {
                setErrorText('');
                await handleSubmit();
            } else {
                setErrorText(response.data.error || 'Something went wrong');
            }
        } catch (error) {
            setErrorText(error.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    }, [email, handleSubmit]);

    const handleChangeEmail = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
        []
    );

    const handleChangeUsername = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value),
        []
    );

    if (isSuccess) {
        return (
            <div className={cx(classes.root, className)}>
                <div className={classes.textsContainer}>
                    <h4>{'Registration success!'}</h4>
                    <p>{'Message with account activation instructions was sent to your email'}</p>
                </div>
                <div className={classes.actionsContainer}>
                    <Button component={Link} to={buildLink('/')} color="primary" fullWidth>
                        {trial ? 'Back' : 'To home page'}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={cx(classes.root, className)}>
            {showHeader && (
                <div className={classes.textsContainer}>
                    <h1>{'Sign up'}</h1>
                    <p>
                        {'Already have an account? '}
                        <Link className={classes.link} to={buildLink('/login')}>
                            {'Sign in'}
                        </Link>
                    </p>
                </div>
            )}

            <div className={classes.fieldsContainer}>
                <TextField fullWidth variant="outlined" name="username" label="Login" onChange={handleChangeUsername} />
                <TextField fullWidth name="email" label="Email" onChange={handleChangeEmail} />
                <PasswordField onChange={setPassword} onSubmit={handleSubmit} />
                <FormControlLabel
                    control={<Checkbox checked={eula} color="primary" onChange={toggleEula} />}
                    label={
                        <Typography variant="body2" className={classes.checkBoxText}>
                            {'I agree to the Vulners '}
                            <Typography
                                variant="body2"
                                className={classes.checkBoxText}
                                gutterBottom
                                component="a"
                                color="primary"
                                href="static/docs/eula.pdf"
                                target="_blank"
                            >
                                {'User License Agreement'}
                            </Typography>
                        </Typography>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={marketingAgreement} color="primary" onChange={toggleMarketingAgreement} />
                    }
                    label={
                        <Typography variant="body2" className={classes.checkBoxText}>
                            {'I agree to receive the newsletter in accordance with the Vulners '}
                            <Typography
                                variant="body2"
                                className={classes.checkBoxText}
                                gutterBottom
                                component="a"
                                color="primary"
                                href="/static/docs/privacy_policy.html"
                                target="_blank"
                            >
                                {'Privacy Policy'}
                            </Typography>
                        </Typography>
                    }
                />
                {eula && captcha}
                {errorText && <p className={classes.error}>{errorText}</p>}
            </div>

            <div className={classes.actionsContainer}>
                {isLoading && <LinearProgress variant="indeterminate" className={classes.loader} />}
                <Button
                    fullWidth
                    color="primary"
                    className={classes.submitButton}
                    variant="contained"
                    onClick={handleSignUpClick}
                    disabled={!eula || isLoading}
                >
                    {'Sign up'}
                </Button>
                <div className={classes.oauthDivider}>{'OR'}</div>
                <OAuthButtonGroup
                    disabled={!eula || isLoading}
                    {...oAuthProps}
                    enterprise={trial ? false : enterprise}
                />
            </div>
        </div>
    );
};

export default SignUp;
