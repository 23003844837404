import { SearchFieldValue, SearchType } from 'main/components/SearchBar/types';

export const searchTypeOptions = [
    {
        value: SearchType.elastic,
        label: 'Basic search'
    },
    {
        value: SearchType.simple,
        label: 'Lucene search'
    },
    {
        value: SearchType.product,
        label: 'Search by product'
    }
] as const;

export const optionByValue = searchTypeOptions.reduce(
    (acc, option) => ({ ...acc, [option.value]: option }),
    {} as Record<SearchType, typeof searchTypeOptions[number]>
);

export const defaultSearchBarValue: SearchFieldValue = {
    type: SearchType.elastic,
    query: '',
    software: '',
    version: ''
};

export const emptyArray = [];

export const defaultSimpleSearchSuggestions = [
    'type',
    'published',
    'lastseen',
    'modified',
    'title',
    'bounty',
    'cpe',
    'order',
    'cvss.score'
];
